import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/Pascal_Watwat.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Test = styled.div`
  ${tw`flex items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero>
        <Test>
          <div>
            <div>
              <Avatar src={avatar} alt="Pascal Watwat" />
            </div>
            <BigTitle>
              Hello, <br /> I'm Pascal Watwat.
            </BigTitle>
          </div>
        </Test>
        <Subtitle>
          I am a Digital Economist, an Agile Portfolio Manager, a Digital Transformation Catalyst, a Business Developer,
          and a Perfume Lover{' '}
        </Subtitle>
      </Hero>
      <Projects>
        <Title>Experience</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="Postlight"
            link="https://www.postlight.com"
            bg="linear-gradient(to right, #2A0AD0 0%, #7E33C0 100%)"
          >
            I am currently a Program Manager at Postlight a digital product and services studio based in New York City.
            
          </ProjectCard>
          <ProjectCard
            title="KEEWARD & AlumniUSJ"
            link="http://www.alumniusj.org"
            bg="linear-gradient(to right, #75C1C2 0%, #283177 100%)"
          >
            I am member of the Strategic Development Committee in charge of restructuring and enhancing the operating model, and lead the Digital Transformation of the Alumni Federation of associations <br />

            I joined KEEWARD in 2013, I worked as a Digital Project Manager, a Product Manager for Logistics and
            Software as a Service in the Vacation rentals and Cultural industries, and as a Channel Manager for Books
            distribution in Beirut, Paris, London and New York.
          </ProjectCard>
          <ProjectCard
            title="Digital Transformation"
            link="https://areeba.com/"
            bg="linear-gradient(to right, #DF5D50 0%, #863F91 100%)"
          >
               I worked with SOGEAS an Insurance portfolio Management company and, on a Digital Transformation, starting
            with their operational model, to their database architecture and structure, and their process optimization. <br />
            I was part of a Task force to do a System Transformation at areeba, using Agile and Kaizen, in order to turn
            this company into a powerful Fintech Engine. 
          </ProjectCard>
          <ProjectCard
            title="Squash, Perfume, Food & Calligraphy"
            link="https://www.instagram.com/watwattotaste/"
            bg="linear-gradient(to right, #791744 0%, #E3C63C 100%)"
          >
            I am porfessional Squash trainer in charge of the official team of the University of Saint Joseph of Beirut. <br />
            I created my first Perfume in Paris in 2017; "Pascal #1" a vibrant and overflowing freshness of neroli accentuated with many oriental notes and spices. <br />
            "Watwat to taste" is my personal food directory. I enjoy documenting the delicious food and drinks I taste everywhere I go around the world. <br />
            I love Calligraphy, I have some nice projects in mind.. stay tuned!
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Pascal Watwat" />
          <AboutSub>I am a Digital Economist by training and a Catalyst and Program Manager by practice.</AboutSub>
        </AboutHero>
        <AboutDesc>
          My experience and proficiencies helped me manage products in many industries that required a cross-departments
          input and cross-functional teams using Agile and Kaizen methodologies. I am passionate about creating networks
          and strategic partnerships with the primary objective of perpetually adding value. I am a Squash Instructor as
          well, during my free time.
        </AboutDesc>
      </About>
      <Contact>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            Say <a href="mailto:mail@pascalwatwat.com">Hi</a> or find me on other platforms:{' '}
            <a href="https://www.linkedin.com/in/pascal-watwat-748a2933/">LinkedIn</a> &{' '}
            <a href="https://www.instagram.com/pascalwatwat/">Instagram</a>
          </ContactText>
        </Inner>
        <Footer>&copy; 2019 by Pascal Watwat.</Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
